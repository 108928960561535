import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import logo from '../../../images/loader-goldi-transparent.gif';

export const FeastPage = () => {
  const [checked, setChecked] = useState(false);

  const splash_screen = {
    backgroundColor: '#fff',
    height: '100vh',
    width: '100%',
    display: 'flex',
    position: 'fixed',
    padding: 9,
    zIndex: 999999,
    opacity: 0.95,
  };

  const img_style = {
    width: 160,
    margin: '0 auto 16px auto',
    // display: 'initial',
  };

  useEffect(() => {
    setTimeout(() => {
      setChecked(true);
    }, 1000);
  }, []);

  return (
    <Box sx={splash_screen} maxWidth='sm'>
      <Grid container sx={{ margin: 'auto' }}>
        <Grid item xs={12} textAlign={'center'}>
          <img src={logo} style={img_style} />
          <Typography variant='h4' color='#979AA3' textAlign='center'>
            Hold tight!
            <br />
            Goldi&apos;s crafting your
            <br />
            JUST RIGHT
            <br />
            dishes.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
