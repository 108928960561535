import { Box, Dialog, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import UserAuthLogin from '../../Auth/Login';
import { SecondaryActionButton } from './SecondaryActionButton';
import { useParams } from 'react-router-dom';

const style = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  textAlign: 'center',
  display: 'block',
  width: '100%',
};

interface Props {
  setVideoPlayed(val: boolean): any;
}

function GetStarted({ setVideoPlayed }: Props) {
  const [isLess, setIsLess] = useState(true);
  const [redirectURI, setRedirectURI] = useState('');

  const useStyles = makeStyles({
    paper: {
      position: 'absolute',
      bottom: '-50px',
      width: '100%',
      paddingBottom: '70px',
      borderRadius: '0px!important',
    },
  });
  const classes = useStyles();
  const { restaurantId } = useParams();
  useEffect(() => {
    if (restaurantId) {
      setRedirectURI('/explore/'.concat(restaurantId));
    }
  }, [restaurantId]);

  return (
    <Box sx={style}>
      <Grid container>
        <Grid item xs={12} mb={'20px'}>
          <SecondaryActionButton
            title={'Get Started'}
            onClick={() => setVideoPlayed(true)}
          />
        </Grid>
        <Grid item xs={12} mb={'20px'}>
          <Dialog
            classes={{ paper: classes.paper }}
            open={!isLess}
            onClose={() => setIsLess(true)}
          >
            <UserAuthLogin
              redirectURI={redirectURI}
              onClose={() => setIsLess(true)}
            />
          </Dialog>
          <SecondaryActionButton
            title={'Log In'}
            onClick={() => {
              setIsLess(false);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default GetStarted;
