import { Box, Dialog, Divider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import expand from 'images/icons/add.png';
import { MenuItem } from 'interfaces/MenuInterface';
import { MenuItemDetail } from 'modules/explorer/MenuItemDetail';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { useState } from 'react';
import { useCart } from '../context/CartContext';
import {
  formatCentsToDollars,
  getCustomizationMenuMessages,
  getMatchingCustomizations,
} from '../util/util';
import toPriceDisplay from 'Utils/helpers/toPriceDisplay';

const disjunctionListFormatter = new (Intl as any).ListFormat('en', {
  style: 'long',
  type: 'disjunction',
});

interface Props {
  menuItem: MenuItem;
}

export const MenuItemCard = ({ menuItem }: Props) => {
  const {
    id,
    title,
    description,
    price: initialPrice,
    menuItemCustomizations,
    disabled,
  } = menuItem;
  const [price, setPrice] = useState(initialPrice);
  const [isLess, setIsLess] = useState(true);
  const { isInCart, addToCart, removeFromCart } = useCart();
  const { enabledAllergies, enabledDiets } = useDietaryPreferences();

  // Filter customizations based on user preferences
  // const {filteredCustomizations, = getMatchingCustomizations(menuItemCustomizations, {
  //   enabledAllergies,
  //   enabledDiets,
  // });

  const { filteredItems, omit } = getMatchingCustomizations(
    menuItemCustomizations,
    {
      enabledAllergies,
      enabledDiets,
    }
  );
  const customizations = filteredItems.filter(
    ({ menuItemCustomizationOptions }) =>
      menuItemCustomizationOptions.length > 0
  );

  const initialCustomizationOptionIds = customizations.reduce(
    (acc, customization) => {
      const { menuItemCustomizationOptions } = customization;
      const defaultModifierIds = menuItemCustomizationOptions
        .filter((option) => option.defaultModifier)
        .map((opt) => opt.id)
        .filter((id): id is string => id !== undefined); // Filter out undefined values

      acc = [...acc, ...defaultModifierIds];
      return acc;
    },
    [] as string[]
  );

  const { omits, substitutions, addOns } = getCustomizationMenuMessages(
    menuItemCustomizations,
    enabledAllergies,
    enabledDiets
  );

  const useStyles = makeStyles({
    paper: {
      position: 'absolute',
      bottom: '-50px',
      width: '100%',
      paddingBottom: '70px',
      borderRadius: '0px!important',
    },
    disabledItem: {
      opacity: 0.5, // Grayed-out for disabled items
      pointerEvents: 'none', // Prevent interaction with disabled items
    },
  });
  const classes = useStyles();

  // // Select default options for each customization
  // const initialCustomizationOptionIds = filteredCustomizations.reduce(
  //   (acc, customization) => {
  //     const { menuItemCustomizationOptions } = customization;
  //     const defaultModifierIds = menuItemCustomizationOptions
  //       .filter((option) => option.defaultModifier)
  //       .map((opt) => opt.id as string);
  //     return [...acc, ...defaultModifierIds];
  //   },
  //   [] as string[]
  // );

  const [selectedCustomizationOptionIds, setSelectedCustomizationOptionIds] =
    useState<string[]>(initialCustomizationOptionIds);

  const handleAddItemToOrder = (price: number, quantity: number) => {
    const customizationsOrder = customizations
      .filter(({ menuItemCustomizationOptions }) =>
        menuItemCustomizationOptions.some(({ id }) =>
          selectedCustomizationOptionIds.includes(id ?? '')
        )
      )
      .map((customization) => {
        const { filteredItems } = getMatchingCustomizations(
          customization.menuItemCustomizationOptions,
          {
            enabledAllergies,
            enabledDiets,
          }
        );

        return {
          ...customization,
          menuItemCustomizationOptions: filteredItems.filter(({ id }) =>
            selectedCustomizationOptionIds.includes(id ?? '')
          ),
        };
      });

    addToCart({
      userId: '1',
      item: {
        menuItem,
        customizations: customizationsOrder,
        price,
        quantity,
        size: quantity, // Adjust if size is intended to be based on quantity
      },
    });
  };

  const handleOnTapped = (menuItem: MenuItem) => {
    if (isInCart(menuItem)) {
      removeFromCart({
        userId: '1',
        item: {
          menuItem,
          customizations: [],
          price: 0,
          quantity: 0,
          size: 0,
        },
      });
    }
  };

  const dialogProperties = {
    bottom: '-20%',
    height: '90%',
    width: '100%',
    top: 'auto',
  };

  const hasDetail =
    omits.length > 0 || substitutions.length > 0 || addOns.length > 0;

  return (
    <>
      <Stack
        key={id}
        direction='column'
        className={disabled ? classes.disabledItem : ''}
      >
        <Stack
          direction='row'
          alignItems='top'
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            if (!disabled) {
              setIsLess(!isLess);
            }
          }}
        >
          {/* <MenuItemImage menuItem={menuItem} /> */}
          <Stack alignItems='start'>
            <Typography
              fontWeight={500}
              variant='h6'
              color={disabled ? 'gray' : 'black'}
            >
              {title}
            </Typography>
            <Typography variant='caption' color={disabled ? 'gray' : 'black'}>
              {truncateString(description ?? '', 500)}
            </Typography>
            <Typography
              variant='caption'
              color={disabled ? 'gray' : 'black'}
              mt={hasDetail ? 2 : 0}
            >
              <>
                <p>
                  <>
                    {omits.length > 0 && (
                      <>
                        <strong>Omits:</strong> {omits.join(', ')}
                        <br />
                      </>
                    )}
                  </>
                  <>
                    {substitutions.length > 0 && (
                      <>
                        <strong>Substitute(s):</strong>
                        <ul>
                          {substitutions.map((sub: any) => (
                            <li key={sub.target.id}>
                              {`- ${disjunctionListFormatter.format(
                                sub.options.map(
                                  (opt: any) =>
                                    `${opt.title}${
                                      opt.price > 0
                                        ? ` (${toPriceDisplay(opt.price)})`
                                        : ''
                                    }`
                                )
                              )} for ${sub.target.title}`}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </>
                  <>
                    {addOns.length > 0 && (
                      <>
                        <strong>Add On(s):</strong>
                        <ul>
                          {addOns.map((addOn: any) => (
                            <li key={addOn.id}>
                              {`- ${addOn.title} ${
                                addOn.price > 0
                                  ? `(${toPriceDisplay(addOn.price)})`
                                  : ''
                              }`}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </>
                  {/* Click <img
                        src={expand}
                        style={{ maxWidth: '10px', marginTop: '0', verticalAlign: 'middle', display: 'inline-block', cursor: disabled ? 'not-allowed' : 'pointer' }}
                        onClick={() => !disabled && handleOnTapped(menuItem)}
                        alt="expand icon"
                      /> to customize this dish as per your needs. */}
                </p>
              </>
            </Typography>
          </Stack>
          <Box sx={{ flex: 1 }} />
          <Stack spacing={1} alignItems='center'>
            <Typography
              fontWeight={500}
              variant='subtitle1'
              color={disabled ? 'gray' : 'black'}
            >
              {formatCentsToDollars(price)}
            </Typography>
            <img
              src={expand}
              style={{
                maxWidth: '30px',
                marginTop: 4,
                cursor: disabled ? 'not-allowed' : 'hand',
              }}
              onClick={() => !disabled && handleOnTapped(menuItem)}
            />
          </Stack>
        </Stack>

        <Dialog
          classes={{ paper: classes.paper }}
          open={!isLess}
          onClose={() => setIsLess(true)}
        >
          <MenuItemDetail
            menuItem={menuItem}
            onClose={() => setIsLess(true)}
            selectedCustomizationOptionIds={selectedCustomizationOptionIds}
            setSelectedCustomizationOptionIds={
              setSelectedCustomizationOptionIds
            }
            filteredCustomizations={customizations}
            handleAddItemToOrder={handleAddItemToOrder}
            setCurrentPrice={setPrice}
          />
        </Dialog>
      </Stack>
      <Divider />
    </>
  );
};

function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}
