import * as ROUTES from 'Utils/constants/routes';
import LoadingPage from 'components/LoadingPage';
import { auth } from 'firebase';
import NewMerchant from 'modules/admin_portal/NewMerchant';
import AdminReportings from 'modules/admin_portal/client_sidebar/AdminReportings';
import ClientContent from 'modules/admin_portal/client_sidebar/client_content';
import Diets from 'modules/admin_portal/main_sidebar/Diets';
import Ingredients from 'modules/admin_portal/main_sidebar/Ingredients';
import ForgotPassword from 'modules/common/AuthModules/ForgotPassword';
import Login from 'modules/common/AuthModules/Login';
import ResetLinkSent from 'modules/common/AuthModules/ResetLinkSent';
import ResetPassword from 'modules/common/AuthModules/ResetPassword';
import SignInWithLink from 'modules/common/AuthModules/SignInWithLink';
import MenuAutoAssignIngredients from 'modules/merchant_portal/MenuAutoAssignIngredients';
import MenuDetails from 'modules/merchant_portal/MenuDetails';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminAccountSettings from '../admin_portal/client_sidebar/AdminAccountSettings';
import AdminOrders from '../admin_portal/client_sidebar/AdminOrders';
import AdminRestaurants from '../admin_portal/client_sidebar/AdminRestaurants';
import Integrations from '../admin_portal/client_sidebar/Integrations';
import AdminGlobalSettings from '../admin_portal/main_sidebar/AdminGlobalSettings';
import AdminMainAccountSettings from '../admin_portal/main_sidebar/AdminMainAccountSettings';
import Dashboard from '../common/Dashboard';
import ExplorerLayout from '../common/ExplorerLayout';
import SetNewPassword from '../common/SetNewPassword';
import EditRestaurant from '../merchant_portal/EditRestaurant';
import NewRestaurant from '../merchant_portal/NewRestaurant';
import Restaurants from '../merchant_portal/Restaurants';
import RestaurantDetails from '../merchant_portal/Restaurants/RestaurantDetails';
import AccountSettings from '../merchant_portal/sidebar/AccountSettings';
import Reportings from '../merchant_portal/sidebar/Reporting';
import Support from '../merchant_portal/sidebar/Support';
// import { setEmailVerificationStatus, setUserType } from 'features/user-slice';
import EditMerchant from 'modules/admin_portal/EditMerchant';
import Allergies from 'modules/admin_portal/main_sidebar/Allergies';
import OauthAuthorize from 'modules/common/AuthModules/OauthAuthorize';
import RedirectWithLink from 'modules/common/AuthModules/RedirectWithLink';
import ExploreTnC from 'modules/common/TermsAndConditions';
import ExploreRestaurant from 'modules/explorer/ExploreRestaurant';
import OrderDetail from 'modules/explorer/ExploreRestaurant/OrderDetail';
import ExploreRestaurantDetail from 'modules/explorer/ExploreRestaurant/RestaurantDetail';
import ExploreRestaurantOnboarding from 'modules/explorer/ExploreRestaurant/onboarding';
import { OrderDetails } from 'modules/merchant_portal/Orders/OrderDetails';
import AuthService from 'services/auth.service';
import { UserSignUp } from 'modules/explorer/ExploreRestaurant/UserSignUp/UserSignUp';

const Routers = () => {
  const [user, loading] = useAuthState(auth);
  const [userType, setUserType] = useState();
  // const { useAppDispatch, useAppSelector } = useCustomHook();
  // const dispatch = useAppDispatch();
  // const userType = useAppSelector((state) => state.user.role);
  // const isVerified = useAppSelector((state) => !state.user.isAuthenticated);  //State is inverted only for development purposes

  const checkUserRole = async () => {
    const { roleType } = await AuthService.getCurrentUserRole();

    console.log(roleType, roleType);
    setUserType(roleType);
    // if(!roleType || roleType==undefined){
    //   setUserType(ROLE_TYPES.USER)
    // }
    // else{
    // setUserType(roleType)
    // }
  };

  useEffect(() => {
    if (user) checkUserRole();
  }, [user, userType]);

  //TODO: clean up these routes later, ideally return only one <Routes> component

  // if loading, return LoadingPage
  if (loading) return <LoadingPage />;

  // if user is not logged in, return default routes
  if (!user) {
    return (
      <Routes>
        <Route path={'/explore'} element={<ExplorerLayout />}>
          <Route
            path={ROUTES.EXPLORE_RESTAURANT_ID}
            element={<ExploreRestaurant />}
          />
          <Route
            path={ROUTES.EXPLORE_RESTAURANT_ONBOARDING}
            element={<ExploreRestaurantOnboarding />}
          />
          <Route
            path={ROUTES.EXPLORE_RESTAURANT_MENU}
            element={<ExploreRestaurantDetail />}
          />
          <Route
            path={ROUTES.EXPLORE_RESTAURANT_CART}
            element={<OrderDetail isEditable={true} />}
          />
          <Route
            path={ROUTES.EXPLORE_RESTAURANT_ORDER}
            element={<OrderDetail isEditable={false} />}
          />
          <Route path={ROUTES.EXPLORE_SIGNUP} element={<UserSignUp />} />
        </Route>
        <Route path='/' element={<Login />} />
        <Route path={ROUTES.SIGN_IN} element={<Login />} />
        <Route path={ROUTES.SIGN_UP} element={<Login />} />
        <Route path={ROUTES.RESET_LINK_SENT} element={<ResetLinkSent />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.EXPLORE_TNC} element={<ExploreTnC />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={ROUTES.FINISH_SIGN_UP} element={<SignInWithLink />} />
        {/* <Route path='*' element={<Navigate to='/' />} /> */}
        <Route path='*' element={<RedirectWithLink />} />
      </Routes>
    );
  }

  // if user is logged in, return LoadingPage while we load userType
  if (user && !userType) return <LoadingPage />;

  if (userType === 'MerchantAdmin') {
    return (
      <Routes>
        <Route
          path='/'
          element={<Navigate to={`/merchants/${user.uid}/restaurants`} />}
        />
        <Route path={'/'} element={<Dashboard />}>
          {/* <Route path={ROUTES.DASHBOARD} element={<Navigate to={ROUTES.RESTAURANTS} />} /> */}
          <Route
            path={`/merchants/:merchantId/restaurants`}
            element={<Restaurants />}
          />
          <Route
            path={`/merchants/:merchantId/restaurants/new`}
            element={<NewRestaurant />}
          />
          <Route
            path={ROUTES.RESTAURANT_DETAILS}
            element={<RestaurantDetails />}
          />
          <Route path={ROUTES.RESTAURANT_EDIT} element={<EditRestaurant />} />
          <Route path={ROUTES.ACCOUNT_SETTINGS} element={<AccountSettings />} />
        </Route>
        <Route path={ROUTES.MENU_DETAILS} element={<MenuDetails />} />
        <Route path={ROUTES.REPORTINGS} element={<Reportings />} />

        <Route path={ROUTES.SUPPORT} element={<Support />} />
        <Route path={ROUTES.SET_NEW_PASSWORD} element={<SetNewPassword />} />
        <Route path={ROUTES.OAUTH_AUTHORIZE} element={<OauthAuthorize />} />
        <Route path={'/explore'} element={<ExplorerLayout />}>
          <Route
            path={ROUTES.EXPLORE_RESTAURANT_ID}
            element={<ExploreRestaurant />}
          />
        </Route>
        <Route path='*' element={<Navigate to={'/'} />} />
      </Routes>
    );
  } else if (userType === 'SuperAdmin') {
    return (
      <Routes>
        <Route path='/' element={<Navigate to={ROUTES.DASHBOARD} />} />
        <Route path={'/'} element={<Dashboard />}>
          <Route path={ROUTES.DASHBOARD} element={<ClientContent />} />
          <Route path={ROUTES.MERCHANTS} element={<ClientContent />} />
          <Route path={ROUTES.CREATE_CLIENT} element={<NewMerchant />} />
          <Route path={ROUTES.EDIT_CLIENT} element={<EditMerchant />} />
          <Route path={ROUTES.MERCHANT_RESTAURANTS} element={<Restaurants />} />
          <Route
            path={ROUTES.MERCHANT_RESTAURANT_NEW}
            element={<NewRestaurant />}
          />
          <Route
            path={ROUTES.MERCHANTS_REPORTING}
            element={<AdminReportings />}
          />
          <Route path={ROUTES.ADMIN_ORDERS} element={<AdminOrders />} />
          <Route
            path={ROUTES.ADMIN_RESTAURANTS}
            element={<AdminRestaurants />}
          />
          <Route
            path={ROUTES.MERCHANTS_INTEGRATIONS}
            element={<Integrations />}
          />
          <Route
            path={ROUTES.RESTAURANT_DETAILS}
            element={<RestaurantDetails />}
          />
          <Route path={ROUTES.RESTAURANT_EDIT} element={<EditRestaurant />} />

          <Route
            path={ROUTES.ADMIN_ORDERS_DETAIL}
            element={<OrderDetails isEditable={false} />}
          />

          <Route
            path={ROUTES.MERCHANTS_ACCOUNT_SETTINGS}
            element={<AdminAccountSettings />}
          />
          <Route path={ROUTES.DIETS} element={<Diets />} />
          <Route path={ROUTES.INGREDIENTS} element={<Ingredients />} />
          <Route path={ROUTES.ALLERGIES} element={<Allergies />} />
          <Route
            path={ROUTES.ADMIN_GLOBAL_SETTINGS}
            element={<AdminGlobalSettings />}
          />
          <Route
            path={ROUTES.ACCOUNT_SETTINGS}
            element={<AdminMainAccountSettings />}
          />
        </Route>
        <Route path={ROUTES.MENU_DETAILS} element={<MenuDetails />} />
        <Route
          path={ROUTES.MENU_AUTO_ASSIGN_INGREDIENTS}
          element={<MenuAutoAssignIngredients />}
        />

        <Route path={'/explore'} element={<ExplorerLayout />}>
          <Route
            path={ROUTES.EXPLORE_RESTAURANT_ID}
            element={<ExploreRestaurant />}
          />
          <Route
            path={ROUTES.EXPLORE_RESTAURANT_ONBOARDING}
            element={<ExploreRestaurantOnboarding />}
          />
          <Route
            path={ROUTES.EXPLORE_RESTAURANT_MENU}
            element={<ExploreRestaurantDetail />}
          />
          <Route
            path={ROUTES.EXPLORE_RESTAURANT_CART}
            element={<OrderDetail isEditable={true} />}
          />
          <Route
            path={ROUTES.EXPLORE_RESTAURANT_ORDER}
            element={<OrderDetail isEditable={false} />}
          />
        </Route>
        <Route path='*' element={<Navigate to={ROUTES.DASHBOARD} />} />
      </Routes>
    );
  } else if (userType === 'OrderAdmin') {
    return (
      <Routes>
        <Route path='/' element={<Navigate to={ROUTES.ADMIN_ORDERS} />} />
        <Route path={'/'} element={<Dashboard />}>
          <Route path={ROUTES.DASHBOARD} element={<ClientContent />} />
          <Route path={ROUTES.ADMIN_ORDERS} element={<AdminOrders />} />
          <Route
            path={ROUTES.ADMIN_ORDERS_DETAIL}
            element={<OrderDetails isEditable={false} />}
          />
        </Route>
      </Routes>
    );
  } else {
    return <LoadingPage />;
  }
};

export default Routers;
