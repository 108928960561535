import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../../../images/Goldi-Animation.svg';
import { PrimaryActionButton } from '../components/PrimaryActionButton';
import { useEffect, useState } from 'react';
import enabledLogo from '../../../../images/sunburst.enabled.png';
import Confetti from 'react-confetti';

export const UserSignUp = () => {
  const theme = useTheme();
  const { restaurantId } = useParams();
  const navigate = useNavigate();

  const [runConfetti, setRunConfetti] = useState(true);
  const [isSignedUp, setIsSignedUp] = useState(false);

  useEffect(() => {
    if (!isSignedUp) {
      return;
    }

    const timer = setTimeout(() => {
      setRunConfetti(false);
    }, 5000); // Stop after 5 seconds

    return () => clearTimeout(timer); // Clean up the timeout on unmount
  }, [isSignedUp]);

  const orderHeader2 = {
    py: 2,
    top: 0,
    width: '100%',
    zIndex: 999,
    textAlign: 'center',
    // color: theme.palette.grey[0],
    // backgroundColor: "#fe8154;",
    borderBottom: '1.5px solid #F0BD4E',
  };

  const goToLink = () => {
    console.log('go to link');
    if (restaurantId) {
      navigate('/explore/' + restaurantId + '/menu');
    } else {
      navigate(-1);
    }
  };

  const handleSubmit = () => {
    setIsSignedUp(true);
  };

  return (
    <Stack sx={{ backgroundColor: theme.palette.grey[0] }}>
      <Stack sx={orderHeader2} direction='row' alignContent='center'>
        <ArrowBackIosNewIcon
          onClick={goToLink}
          sx={{
            zIndex: 100,
            cursor: 'pointer',
            color: '#1E423A',
            height: '20px',
            marginTop: 1.5,
            marginLeft: 2,
            marginRight: -5.5,
          }}
        />
        <Box flex={1} />
        <img
          src={logo}
          alt='Goldi'
          style={{
            height: '42px', // Adjust size as needed
            objectFit: 'contain',
            padding: 4,
            cursor: 'pointer', // Indicates clickability
          }}
          onClick={() =>
            window.open(
              'https://www.ordergoldi.com/',
              '_blank',
              'noopener,noreferrer'
            )
          }
        />
        <Box flex={1} />
      </Stack>
      {isSignedUp ? (
        <Stack
          height='100vh'
          justifyContent='center'
          alignItems='center'
          gap={2}
        >
          <img src={enabledLogo} width='120px' />
          <Typography variant='h3' textAlign='center'>
            Thank you for signing up!
          </Typography>
          <Confetti recycle={runConfetti} />
        </Stack>
      ) : (
        <Stack p={4} gap={3}>
          <Stack px={-4}>
            <Typography variant='body2' textAlign='center'>
              Enjoy your Goldi experience?
            </Typography>
            <Typography variant='body2' textAlign='center'>
              Create an account for faster service
            </Typography>
            <Typography variant='body2' textAlign='center'>
              in the future.
            </Typography>
          </Stack>
          <TextField label='Name' variant='outlined' />
          <TextField label='Email' variant='outlined' type='email' />
          <TextField label='Phone Number' variant='outlined' type='number' />
          <TextField label='Password' type='password' variant='outlined' />
          <Box ml={2} alignSelf='center' width={300}>
            <PrimaryActionButton title={'Submit'} onClick={handleSubmit} />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};
