import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { DietPreference } from 'interfaces/DietPreferenceInterface';

export interface DietPreferenceItemProps {
  preference: DietPreference;
}

export const DietPreferenceItem = ({ preference }: DietPreferenceItemProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
        textAlign: 'center',
        width: '70px',
        height: '75px',
      }}
    >
      <img
        src={preference.filledIconUrl}
        style={{
          height: '36px',
          width: '36px',
          display: 'inline-block',
          marginBottom: -12,
        }}
      />
      <Typography
        mt={1}
        sx={{
          color: theme.palette.secondary.main,
        }}
        variant='subtitle2'
      >
        {preference.name}
      </Typography>
    </Box>
  );
};
