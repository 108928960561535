import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ConfirmDialog from '../components/ConfirmDialog';
import { ErrorActionButton } from '../components/ErrorActionButton';
import GroupedButtons from '../components/GroupedButtons';
import { useCart } from '../context/CartContext';
import { CartItem } from '../model/cart';
import { formatCentsToDollars } from '../util/util';

interface Props {
  item: CartItem;
  isEditable: boolean;
  updateQuantity: (obj: any) => void;
  handleOnMenuItemDelete: (obj: any) => void;
  index: number;
  orderCustomizations: any;
  userId: string;
}

export const UserOrderMenuItem = ({
  item,
  isEditable,
  updateQuantity,
  index,
  handleOnMenuItemDelete,
  userId,
  orderCustomizations,
}: Props) => {
  const { removeFromCart, addToCart } = useCart();
  const { menuItem, customizations, price, customisedPrice, category } = item;
  const [openDialog, setOpenDialog] = useState(false);
  const instructionsObj: any = {
    omits: {},
    add: {},
    substitution: {},
  };
  const [instructions, setInstructions] = useState(instructionsObj);
  useEffect(() => {
    if (orderCustomizations) {
      instructionsObj.omits =
        Object.keys(orderCustomizations.omitOptions).length > 0 &&
        Object.keys(orderCustomizations.omitOptions)
          .filter((key: any) => {
            return orderCustomizations.omitOptions[key].length > 0;
          })
          .map((key) => {
            return orderCustomizations.omitOptions[key].join(', ');
          });

      instructionsObj.add =
        Object.keys(orderCustomizations.addOptions).length > 0 &&
        Object.keys(orderCustomizations.addOptions)
          .filter((key: any) => {
            return orderCustomizations.addOptions[key].length > 0;
          })
          .map((key) => {
            return orderCustomizations.addOptions[key].join(', ');
          });

      instructionsObj.substitution =
        Object.keys(orderCustomizations.substitutionOptions).length > 0 &&
        Object.keys(orderCustomizations.substitutionOptions)
          .filter((key: any) => {
            return orderCustomizations.substitutionOptions[key].length > 0;
          })
          .map((key) => {
            return orderCustomizations.substitutionOptions[key];
          });
    }
    setInstructions(instructionsObj);
  }, [orderCustomizations]);

  const [dataUpdate, setDataUpdate] = useState(false);
  const setSelectedQuantityHandler = (quantity: number) => {
    updateQuantity({
      userId: userId,
      item: {
        menuItem: { size: quantity },
        customizations,
        price,
        quantity,
        size: quantity,
      },
      index,
    });
  };

  const { title, id } = menuItem;
  const subtitle = customizations
    .map(({ title, menuItemCustomizationOptions }) => {
      if (!menuItemCustomizationOptions) {
        return title;
      }

      const options = menuItemCustomizationOptions
        .map(({ title }) => title)
        .join(', ');

      return options.length ? `${title}: ${options}` : '';
    })
    .join('\n');

  const handleOnMenuItemDeleteHandler = () => {
    handleOnMenuItemDelete({
      userId: userId,
      item,
      index,
    });
  };

  const checkConfirmation = (val: boolean, answer: boolean) => {
    if (typeof answer == 'boolean' && answer) {
      handleOnMenuItemDeleteHandler();
    }
    setOpenDialog(val);
  };

  return (
    <Box alignItems='top' sx={{ mb: 1, mt: 3, width: '100%' }}>
      <Box>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant='subtitle2' sx={{ fontSize: 14 }}>
              {title}
            </Typography>
            <Typography
              variant='subtitle2'
              sx={{ fontSize: 14, fontStyle: 'italic' }}
            >
              {category?.title}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: 'right' }}>
            <Typography variant='subtitle2'>
              {customisedPrice
                ? formatCentsToDollars(customisedPrice)
                : formatCentsToDollars(price)}
            </Typography>
          </Grid>
        </Grid>
        {instructions.omits.length > 0 && (
          <Typography variant='subtitle1' sx={{ fontSize: 14 }}>
            Omit: {instructions.omits.join(', ')}
          </Typography>
        )}
        {instructions.add.length > 0 && (
          <Typography variant='subtitle1' sx={{ fontSize: 14 }}>
            Add: {instructions.add.join(', ')}
          </Typography>
        )}
        {instructions.substitution.length > 0 && (
          <>
            <Typography variant='subtitle1' sx={{ fontSize: 14 }}>
              Substitute(s):
            </Typography>
            {instructions.substitution.map((val: any, index: number) => {
              return (
                <Typography
                  variant='subtitle1'
                  key={index}
                  sx={{ fontSize: 14 }}
                >
                  - {val[1].join(', ').concat(' for ', val[0].join(', '))}
                </Typography>
              );
            })}
          </>
        )}

        {!isEditable && (
          <Grid container mt={2}>
            <Grid item xs={6}>
              <Typography variant='subtitle1' sx={{ fontSize: 14 }}>
                {' '}
                Quantity: {item.quantity}{' '}
              </Typography>
            </Grid>
          </Grid>
        )}

        {isEditable && (
          <Grid container mt={2}>
            <Grid item xs={6}>
              <GroupedButtons
                quantity={item.quantity}
                setSelectedQuantity={(val: number) =>
                  setSelectedQuantityHandler(val)
                }
              />
            </Grid>
            <Grid item xs={6} textAlign='right'>
              <Button
                color='secondary'
                variant='text'
                style={{
                  paddingRight: 0,
                  marginRight: -7,
                  textAlign: 'right',
                }}
                onClick={() => setOpenDialog(true)}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        )}
        {openDialog && (
          <ConfirmDialog
            text={'Are you sure you want to delete?'}
            setOpenDialog={(val: boolean, answer: boolean) =>
              checkConfirmation(val, answer)
            }
            openDialog={openDialog}
          />
        )}
        <Divider sx={{ mt: 2 }} />
      </Box>
    </Box>
  );
};
